import SEO from '../components/core/meta/SEO';
import React from 'react';
import 'twin.macro';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import { graphql, useStaticQuery } from 'gatsby';
import { TerzoSettorePagePageQuery } from '../../generated/graphql-types';
import BackgroundImage from 'gatsby-background-image';
import SmallPartnerList from '../components/partials/PartnersList/SmallPartnerList';
import TwoPartLayout from '../components/partials/TwoPartLayout/TwoPartLayout';
import BrochureBanner from '../components/partials/BrochureBanner/BrochureBanner';
import ContactFormButton from '../components/partials/ContactFormButton/ContactFormButton';
import { listItemStyle } from './aziende-farmaceutiche';

const content = [
  'Abbiamo lavorato insieme e mappato i punti di forza e quelli di debolezza, per capire in che modo costruire un modello assistenziale sostenibile.',
  'Per DNA siamo accanto al terzo settore e studiamo il modo per lavorare in sinergia e valorizzare le capacità degli enti no profit ma con una struttura più solida, in grado di affrontare la domanda in frenetico aumento.',
  'Collaboriamo con associazioni e Fondazioni che desiderano allargare i propri servizi e mettere UGO a disposizione dei propri assistiti. Insieme costruiamo progetti da proporre a enti sponsor che ci permettano di garantire il supporto di UGO gratuito o a prezzo calmierato. Mettere a disposizione dell’utente fragile e della sua famiglia UGO significa dare accesso alla salute, sostegno pratico ed emotivo e favorire l’inclusione sociale.',
];

const togetherList = [
  {
    title: "L'impatto del servizio",
    list: [
      '+ 3.000 ore di supporto erogate',
      "+ 20.000 km percorsi per garantire l'accesso alle cure",
      '+ più di 4.000 ore di isolamento colmato',
      '+ 2.000 ore produttive per i caregiver familiari',
      '5/5 livello di soddisfazione',
    ],
  },
];

const TerzoSettorePage = () => {
  const terzoSettorePageData = useStaticQuery<
    TerzoSettorePagePageQuery
  >(graphql`
    query TerzoSettorePagePage {
      headerBackground: file(
        relativePath: { eq: "b2b/headers/together-header.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
      togetherImage: file(relativePath: { eq: "b2b/together.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluidFragment
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO url={'/terzo-settore'} title={'Terzo settore'} />
      <BackgroundImage
        fluid={terzoSettorePageData.headerBackground.childImageSharp.fluid}
      >
        <div
          tw="bg-black bg-opacity-60 py-16 flex"
          style={{ minHeight: '460px' }}
        >
          <Container tw="flex flex-col justify-center">
            <Heading variant="h2" tw="text-center text-white">
              {
                'Profit e no-profit: se lavoriamo insieme, arriviamo molto più lontano.'
              }
            </Heading>
            <div tw="flex justify-center">
              <ContactFormButton />
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <div tw="pt-16">
        <Container tw="mb-16">
          <div tw="mb-8">
            <h3 tw="text-h3-mobile md:text-h3 tracking-h3 font-bold mb-8">
              {
                'UGO è nato da un osservatorio concreto: le associazioni sul territorio.'
              }
            </h3>
            {content.map((m, idx) => (
              <p
                key={idx}
                style={{
                  fontSize: '17px',
                  lineHeight: '32px',
                  marginBottom: '1rem',
                }}
              >
                {m}
              </p>
            ))}
          </div>
          <div tw="flex justify-center">
            <ContactFormButton />
          </div>
        </Container>
        <Container tw="mb-16">
          <SmallPartnerList
            partnerList={[
              'partner10',
              'partner52',
              'partner24',
              'partner53',
              'partner67',
            ]}
          />
        </Container>
        <TwoPartLayout
          imageFluid={terzoSettorePageData.togetherImage.childImageSharp.fluid}
          removeBottomMargin={true}
        >
          <h4 tw="text-h4-mobile md:text-h4 tracking-h4 font-bold mb-8">
            {
              'Con il supporto di Fondazione ANT riusciamo a garantire il supporto di UGO gratuitamente a oltre 200 pazienti oncologici'
            }
          </h4>
          <p
            style={{
              fontSize: '17px',
              lineHeight: '32px',
              marginBottom: '1rem',
            }}
          >
            {
              'Collaboriamo con Fondazione ANT dal 2019 per garantire supporto ai pazienti oncologici e alle loro famiglie. Dall’inizio del progetto abbiamo erogato più di 2.000 servizi e garantito loro accesso alle cure e un aiuto pratico, logistico e anche morale.'
            }
          </p>
          {togetherList.map(p => (
            <>
              <p
                style={{
                  fontSize: '17px',
                  lineHeight: '32px',
                  marginBottom: '1rem',
                }}
              >
                <div tw="mb-4">
                  <b>{p.title}</b>
                </div>
                <ol tw="list-disc" style={{ listStylePosition: 'inside' }}>
                  {p.list.map((l, index) => (
                    <li css={listItemStyle} key={index}>
                      {l}
                    </li>
                  ))}
                </ol>
              </p>
            </>
          ))}
        </TwoPartLayout>
        <BrochureBanner />
      </div>
    </>
  );
};

export default TerzoSettorePage;
